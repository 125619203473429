.login-form-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form-wrapper {
  background-color: #ffffff;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  border-radius: 5px;
}
